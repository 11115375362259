import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ProviderDirectoryFunctionService, EmailRequest, IdEmailRequest, BooleanResponseModel}
  from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { NotificationSystemService, INotification } from 'src/app/common-components/controllers/common-notification-system.controller';


@Injectable({
  providedIn: 'root',
})

export class EmailService implements OnDestroy {
  private _entity: BehaviorSubject<BooleanResponseModel> = new BehaviorSubject<BooleanResponseModel>(
    undefined
  );
  private _parameters: BehaviorSubject<EmailRequest> = new BehaviorSubject<EmailRequest>(
    undefined
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private searchService: ProviderDirectoryFunctionService,
    private notificationSystemService: NotificationSystemService,
  ) {}

  public notify(data?: EmailRequest): void {
    if (data !== undefined) {
      this._parameters.next(data);
    } else {
      data = this._parameters.getValue();
    }
    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    this.searchService.notifyAttendant(data)
    .pipe(
      take(1),
      takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res.isSuccess) {
          this._entity.next(res);
          const notification: INotification = { type: 'success', content: 'Email sent successfully' };
          this.notificationSystemService.setNotification(notification);
        } else {
          const notification: INotification = { type: 'error', content: 'Email failed to send' };
          this.notificationSystemService.setNotification(notification);
        }
      },
      error: error => {
        if (error.message) {
          const notification: INotification = {type: 'error', content: error.message};
          this.notificationSystemService.setNotification(notification);
        }
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public reaffirm(data?: IdEmailRequest[]): void {
    this._load.next(true);
    this.searchService.reaffirmNotification(data)
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res.isSuccess) {
          this._entity.next(res);
          const notification: INotification = { type: 'success', content: 'Email sent successfully' };
          this.notificationSystemService.setNotification(notification);
        } else {
          const notification: INotification = { type: 'error', content: 'Email failed to send' };
          this.notificationSystemService.setNotification(notification);
        }
      },
      error: error => {
        if (error.message) {
          const notification: INotification = {type: 'error', content: error.message};
          this.notificationSystemService.setNotification(notification);
        }
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public getParameters(): Observable<EmailRequest> {
    return this._parameters;
  }

  public setParameters(data: EmailRequest) {
    this._parameters.next(data);
  }

  public getResponse(): Observable<BooleanResponseModel> {
    return this._entity;
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this._entity.next(null);
    this._parameters.next(null);
  }

  public clearEntity(): void {
    this._entity.next(null);
  }

  public clearParamters(): void {
    this._parameters.next(null);
  }

  public clearLoad(): void {
    this._load.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
