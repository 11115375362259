<div class="page-wrapper">
  <!-- SUCCESSFUL REAFFIRM -->
  <div *ngIf="isSuccess === true">
    <p class="page-subtitle-1 m-t-40 textbox-large">{{'page_Attendant_Reaffirm_Success' | langchange}}</p>
  </div>

  <!-- FAILED REAFFIRM -->
  <div *ngIf="isSuccess === false">
    <p class="page-subtitle-1 m-t-40 textbox-large">{{'page_Attendant_Reaffirm_Failure' | langchange}}</p>
  </div>
</div>

<!-- LOADING INDICATOR -->
<div *ngIf="load" class="load">
  <CDMS-common-load-indicator-control
    class="load-indicator"
    [show]="load">
  </CDMS-common-load-indicator-control>
</div>
