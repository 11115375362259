import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { CommonConstants } from '../constants/common-constants';
import { KendoConstants } from '../constants/common-kendo-constants';
import { RegularExpressions } from '../constants/common-regex-constants';

export class ValidationFunctions {

    public static isFullName(value: string): boolean {
        const nameArray = value.split(' ');
        if (nameArray.length !== 2) {
            return false;
        }
        nameArray.forEach(e => {
            if (this.isNumeric(e)) {
                return false;
            }
        });
        return true;
    }

    public static isNumeric(val: any): val is number | string {
        return !Array.isArray(val) && (val - parseFloat(val) + 1) >= 0;
    }

    public static isValidDateRange(begDate: Date, endDate: Date) {
        return (endDate >= begDate);
    }

    public static isDate(value: string): boolean {
        return Object.prototype.toString.call(value) === '[object Date]' ? true : false;
    }

    public static isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }

    public static matchRegularExpression(strRgx: RegExp, value: string): boolean {
        const regexp = new RegExp(strRgx);
        const result = regexp.test(String(value));
        return result;
    }

    public static isKendoDatePickerEmpty(input: string): boolean {
        return KendoConstants.kendoDatePickerValueIfEmpty.indexOf(input) > -1 ? true : false;
    }

    public static phoneNumberValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value > 0) {
            const phLength = String(control.value).trim().length;
            if (phLength > 0 && (phLength < 10 || phLength > 10)) {
                return { invalidPhone: true };
            }
        }
        return null;
    }

    public static phoneExtensionValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        const regexStr = /^[0-9]{0,12}?$/;
        if (control.value > 0 && !ValidationFunctions.matchRegularExpression(regexStr, control.value)) {
            const phLength = String(control.value).trim().length;
            if (phLength > 12) {
                return { invalidPhone: true };
            }
        }
        return null;
    }

    public static validatePastDate(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (control.value > new Date()) {
                return { invalidDate: true };
            }
            if (control.value < new Date(1900, 0, 1)) {
                return { invalidSmallDateMin: true };
            }
        }
        return null;
    }

    public static validateSmallDate(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (control.value < CommonConstants.minSmallDate) {
                return { invalidSmallDateMin: true };
            }
            if (control.value > CommonConstants.maxSmallDate) {
                return { invalidDateMax: true };
            }
        }
        return null;
    }

    public static validateDOB(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (new Date(control.value.toDateString()) >= new Date(new Date().toDateString())) {
                return { invalidDOB: true };
            }
        }
        return null;
    }

    public static zipcodeValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined && String(control.value).length > 0) {
            const zipLength = String(control.value).length;
            const regexStr = RegularExpressions.zipcode;
            if (zipLength > 0 && !ValidationFunctions.matchRegularExpression(regexStr, control.value)) {
                return { invalidZip: true };
            }
        }
        return null;
    }

    public static nameOnlyAlphaWithSpacesValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const regexStr = RegularExpressions.nameWithSpace;
            const retVal = regexStr.test(control.value);
            if (!retVal) {
                return { invalidName: true };
            }
        }
        return null;
    }

    public static nameAlphaWithDiacriticsValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const regexStr = RegularExpressions.nameWithDiacritics;
            const retVal = regexStr.test(control.value);
            if (!retVal) {
                return { invalidName: true };
            }
        }
        return null;
    }

    public static suffixOnlyAlphaWithSpacesValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const regexStr = RegularExpressions.suffixWithSpace;
            const retVal = regexStr.test(control.value);
            if (!retVal) {
                return { invalidSuffix: true };
            }
        }
        return null;
    }

    public static required(control: FormControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined) {
            if (typeof control.value === 'string' && !control.value.trim()) {
                if (control.value.trim() !== control.value) {
                    control.setValue(control.value.trim());
                }
                return {
                    required: true
                };
            }
        } else {
            return {
                required: true
            };
        }

        return null;
    }

    public static requiredEmail(control: FormControl): { [key: string]: boolean } | null {
      if (control.value !== null && control.value !== undefined) {
          if (typeof control.value === 'string' && !control.value.trim()) {
              if (control.value.trim() !== control.value) {
                  control.setValue(control.value.trim());
              }
              return {
                requiredEmail: true
              };
          }
      } else {
          return {
            requiredEmail: true
          };
      }

      return null;
  }

    public static emailValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined && String(control.value).trim().length > 0) {
            const regexp = RegularExpressions.email;
            const result = regexp.test(String(control.value));
            if (!result) {
                return { invalidEmail: true };
            }
        }
        return null;
    }

    public static ssnValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if ((control.value === null) || (control.value === undefined)) {
            return { invalidSSN: true };
        } else if (control.value !== null && control.value !== undefined) {
            const ssnLength = String(control.value).trim().length;
            if (ssnLength > 0 && ssnLength !== 9) {
                return { incompleteSSN: true };
            } else if (!ValidationFunctions.matchRegularExpression(RegularExpressions.ssn, control.value)) {
                return { invalidSSN: true };
            }
        }
        return null;
    }

    public static maskedSSNValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if ((control.value === null) || (control.value === undefined)) {
            return { invalidSSN: true };
        } else if (control.value !== null && control.value !== undefined) {
            const ssnLength = String(control.value).trim().length;
            const ssn = control.value;
            if (!ssn.startsWith('XXX-XX-')) {
                if (ssnLength > 0 && ssnLength !== 9) {
                    return { incompleteSSN: true };
                } else if (!ValidationFunctions.matchRegularExpression(RegularExpressions.ssn, control.value)) {
                    return { invalidSSN: true };
                }
            }
        }
        return null;
    }

    public static OnlyAlphanumericNoSpace(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            if (!ValidationFunctions.matchRegularExpression(RegularExpressions.alphaNumericNoSpace, control.value)) {
                return { invalidText: true };
            }
        }
        return null;
    }

    public static OnlyAlphanumericWithSpace(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            if (!ValidationFunctions.matchRegularExpression(RegularExpressions.alphaNumericWithSpace, control.value)) {
                return { invalidText: true };
            }
        }
        return null;
    }

    public static numberGreaterThanZero(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value <= 0) {
            return { negativeOrZero: true };
        } else {
            return null;
        }
    }

    public static numberGreaterThanOrEqualtoZero(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value < 0) {
            return { negative: true };
        } else {
            return null;
        }
    }

    // multiple control validations
    public static endDateAfterStartDate(group: FormGroup, startDateName: string = 'startDate', endDateName: string = 'endDate') {
        if (group) {
          const startDate: Date = group.controls[startDateName]?.value;
          const endDate: Date = group.controls[endDateName]?.value;
            if (startDate && endDate) {
                if (startDate >= endDate) {
                    group.controls[endDateName].setErrors(
                        { endDateLessThanOrEqualStartDate: true }); // set error for message to appear.
                    return { startDateAfterEndDate: true };
                }
            }
        } else {
            return null;
        }
    }

    public static endDateAfterOrEqualStartDate(group: FormGroup, startDateName: string = 'startDate', endDateName: string = 'endDate') {
        if (group) {
            const startDate: Date = group.controls[startDateName]?.value;
            const endDate: Date = group.controls[endDateName]?.value;
            if (startDate && endDate) {
                if (startDate > endDate) {
                    group.controls[endDateName].setErrors(
                        { endDateLessThanStartDate: true }); // set error for message to appear.
                    return { startDateAfterOrEqualEndDate: true };
                }
            }
        } else {
            return null;
        }
    }
}
