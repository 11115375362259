import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isDeletedFilter',
    pure: false
})
export class IsDeletedFilter implements PipeTransform {
    transform(items: any[]): any {
        return items.filter(item => (item?.isDeleted === undefined || item.isDeleted === false));
    }
}
