import { Injectable, Inject } from '@angular/core';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult,InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from 'src/app/auth-config';

@Injectable()
export default class MsalServiceWrapper
{
    constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService){}

    initiateFlow(flow) {
        // Choose policy authority to initiate flow
        let authorityToUse = '';
        switch(flow) {
            case 'SignInSignUp':
                authorityToUse = b2cPolicies.authorities.signUpSignIn.authority;
                break;
            case 'SignUp':
                authorityToUse = b2cPolicies.authorities.signUp.authority;
                break;
            case 'ChangeSignIn':
                authorityToUse = b2cPolicies.authorities.changeSignInName.authority;
                break;
            case 'PasswordReset':
                authorityToUse = b2cPolicies.authorities.passwordReset.authority;
                break;
            default:
                authorityToUse = b2cPolicies.authorities.signUpSignIn.authority;
        }
        this.redirect(authorityToUse);
    }

    redirect(url, params = {}) {
        if (this.msalGuardConfig.interactionType === InteractionType.Redirect) {
            if (this.msalGuardConfig.authRequest){
                this.authService.loginRedirect({ authority: url, extraQueryParameters: params } as RedirectRequest);
            } else {
                this.authService.loginRedirect();
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ authority: url, extraQueryParameters: params } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
            } else {
                this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
            }
        }
    }
}
