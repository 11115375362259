export class KendoConstants {

    public static kendoMasterGridProperties =
    {
        pageNumber: 1,
        pageSize: 10,
        skip: 0,
        take: 10,
        loading: true,
    };

    public static kendoDetailGridProperties =
    {
        pageNumber: 1,
        pageSize: 5,
        skip: 0,
        loading: true,
    };

    public static kendoLookupGridProperties =
    {
        pageNumber: 1,
        pageSize: 5,
        skip: 0,
        take: 5,
        loading: true,
        toSelectcheckboxOnly: false,
        selectionMode: 'single',
        selectionModeMulti: 'multiple',
    };

    public static readonly kendoDatepickerPlaceHolder = 'MM/DD/YYYY';
    public static readonly kendoDatePickerValueIfEmpty = ['MM/dd/yyyy', '0/dd/yyyy', 'MM/0/yyyy', '0/0/yyyy'];
}
