import { Injectable } from '@angular/core';
import { NotificationService, Position, Animation } from '@progress/kendo-angular-notification';

@Injectable({
    providedIn: 'root'
  })
  export class Notification {
    constructor(
        public notificationService: NotificationService,
    ) {}


    // General Notification Settings
    private _position: Position = { horizontal: 'center', vertical: 'bottom' };
    private _animation: Animation = { type: 'slide', duration: 500 };
    private _cssClass = 'button-notification';

    // Notification Types
    public success(content: string, close = false): void {
      const contents: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-success');
      if (contents.length > 0) {
        for (let i = 0; i < contents.length; i++) {
          const strs = contents[i].textContent.split('\n');
          if (!strs.find(x => x.trim() === content.trim())) {
            content = content + '\n' + contents[i].textContent;
          } else {
            this._animation = { type: 'slide', duration: 0 };
          }
        }
      }

      const elements: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-container');
      if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      }

      this.notificationService.show({
        content,
        cssClass: [this._cssClass, 'button-notification-success'],
        animation: this._animation,
        position: this._position,
        type: { style: 'success', icon: true },
        closable: close,
      });

      this._animation = { type: 'slide', duration: 500 };
    }

    public error(content: string, close = true): void {
      const contents: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-error');
      if (contents.length > 0) {
        for (let i = 0; i < contents.length; i++) {
          const strs = contents[i].textContent.split('\n');
          if (!strs.find(x => x.trim() === content.trim())) {
            content = content + '\n' + contents[i].textContent;
          } else {
            this._animation = { type: 'slide', duration: 0 };
          }
        }
      }

      const elements: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-container');
      if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      }

      this.notificationService.show({
        content,
        cssClass: [this._cssClass, 'button-notification-error'],
        animation: this._animation,
        position: this._position,
        type: { style: 'error', icon: true },
        closable: close,
      });

      this._animation = { type: 'slide', duration: 500 };
    }

    public warning(content: string, close = false): void {
      const contents: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-warning');
      if (contents.length > 0) {
        for (let i = 0; i < contents.length; i++) {
          const strs = contents[i].textContent.split('\n');
          if (!strs.find(x => x.trim() === content.trim())) {
            content = content + '\n' + contents[i].textContent;
          } else {
            this._animation = { type: 'slide', duration: 0 };
          }
        }
      }

      const elements: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-container');
      if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      }

      this.notificationService.show({
        content,
        cssClass: [this._cssClass, 'button-notification-warning'],
        animation: this._animation,
        position: this._position,
        type: { style: 'warning', icon: true },
        closable: close,
      });

      this._animation = { type: 'slide', duration: 500 };
    }

    public info(content: string, close = false): void {
      const contents: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-warning');
      if (contents.length > 0) {
        for (let i = 0; i < contents.length; i++) {
          const strs = contents[i].textContent.split('\n');
          if (!strs.find(x => x.trim() === content.trim())) {
            content = content + '\n' + contents[i].textContent;
          } else {
            this._animation = { type: 'slide', duration: 0 };
          }
        }
      }

      const elements: HTMLCollectionOf<Element> = window.document.getElementsByClassName('k-notification-container');
      if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      }

      this.notificationService.show({
        content,
        cssClass: [this._cssClass, 'button-notification-info'],
        animation: this._animation,
        position: this._position,
        type: { style: 'info', icon: true },
        closable: close,
      });

      this._animation = { type: 'slide', duration: 500 };
    }
  }
