import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../controllers/user/user.controller';

@Injectable()
export class AdminAuthGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router,
        ) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const data = this.userService.getRoleHighestNow();
        if (data !== undefined) {
            if (data === 'ProviderDirectoryAdmin') {
                return true;
            } else {
                this.router.navigate(['/Home']);
                return false;
            }
        } else {
            return false;
        }
    }
}

@Injectable()
export class AttendantAuthGuard implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router,
        ) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const data = this.userService.getRoleHighestNow();
        if (data !== undefined) {
            if (data === 'Attendant' || data === 'ProviderDirectoryAdmin') {
                return true;
            } else {
                this.router.navigate(['/Home']);
                return false;
            }
        } else {
            return false;
        }
    }
}
