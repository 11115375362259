import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { CommonConstants } from '../constants/common-constants';
import { Notification } from './common-notification.service';


@Injectable({
  providedIn: 'root'
})
export class DatePickerCommonService {

  constructor(
    public datePipe: DatePipe,
    public toastr: Notification,
    ) { }

  public validateYear(event: any, ctrl: any, prop: string): void {
    const missingYear = this.getMissingYear(event.target.value);
    if (missingYear !== null && missingYear !== '') {
      ctrl[prop] = new Date(missingYear) ;
    } else {
      const date = ctrl[prop];
      if (this.isValidDate(date)) {
        const retDate = this.getValidYear(date);
        ctrl[prop] =  retDate;
      }
    }
  }

  public validateYearOnBlur(
    date: Date,
    currentValue: string,
    minDate: Date = CommonConstants.minSmallDate,
    maxDate: Date = CommonConstants.maxSmallDate,
    ): Date {
    const missingYear = this.getMissingYearOnBlur(currentValue);
    if (missingYear !== null && missingYear !== '') {
      date = new Date(missingYear) ;
    } else {
      if (this.isValidDate(date)) {
        date = this.getValidYear(date);
      }
      const orgDate = date?.getTime() ?? 0;
      const mnDate = minDate.getTime();
      const mxDate = maxDate.getTime();
      if (orgDate < mnDate) {
        this.toastr.error(CommonConstants.errorMessages.invalidSmallDateMin);
        date = minDate;
      }
      if (orgDate > mxDate) {
        this.toastr.error(CommonConstants.errorMessages.invalidDateMax);
        date = maxDate;
      }
    }
    return date;
  }

  public getMissingYearOnBlur(value: any) {
    let retDate = '';
    const val = value;
    if (val !== null && val !== '') {
      const valArr = val.split('/');
      if (valArr.length > 0 && valArr.length === 3) {
          if (!isNaN(valArr[0]) && !isNaN(valArr[1]) && valArr[2] === 'YYYY') {
            const thisYearStr = new Date().getFullYear().toString();
            const newVal = valArr[0] + '/' + valArr[1] + '/' + thisYearStr;
            retDate = newVal;
        }
      }
    }
    return retDate;
  }

  public validateYearForm(event: any, form: any, prop: string): void {
    const missingYear = this.getMissingYear(event.target.value);
    if (missingYear !== null && missingYear !== '') {
      const dateStr = new Date(missingYear);
      form.controls[prop].patchValue(dateStr) ;
    } else {
      const date = form.get(prop).value;
      if (this.isValidDate(date)) {
        const retDate = this.getValidYear(date);
        form.controls[prop].patchValue(retDate);
      }
    }
  }


  public getMissingYear(val: any) {
    let retDate = '';
    const startVal = '01';
    if (val !== null && val !== '') {
      const valArr = val.split('/');
      if (valArr.length > 0 && valArr.length === 3 && (!isNaN(valArr[0]) || !isNaN(valArr[1]) || !isNaN(valArr[2]))) {
        const month = (isNaN(valArr[0]) || valArr[0] === 'mm') ? startVal : valArr[0];
        const date = (isNaN(valArr[1]) || valArr[0] === 'dd') ? startVal : valArr[1];
        if (valArr[2] === 'yyyy') {
          const thisYearStr = new Date().getFullYear().toString();
          const newVal = month + '/' + date + '/' + thisYearStr;
          retDate = newVal;
        } else {
          retDate = this.datePipe.transform(
            new Date(month + '/' + date + '/' + valArr[2]),
            CommonConstants.defaultDateFormat);
        }
      }
    }
    return retDate;
  }

  public getValidYear(dateIn: any) {
    let inYear = dateIn.getFullYear().toString();
    const inDate = dateIn.getDate();
    const inMonth = dateIn.getMonth();
    const thisYear = new Date().getFullYear();
    const thisCentury = Number(thisYear.toString().substring(0, 1));
    switch (inYear.length) {
      case 1:
        inYear =  (thisCentury * 100) + inYear;
        break;
      case 2:
        inYear =  (thisCentury * 10) + inYear;
        break;
      case 3:
        inYear =  thisCentury + inYear;
        break;
      default:
        inYear = inYear;
    }
    const outDate = new Date(inYear, inMonth, inDate);
    return outDate;
  }

  public isValidDate(date: any) {
    if(typeof(date) === 'string'){
      date = new Date(date);
    }
    return date instanceof Date && !isNaN(date.getTime());
  }


  onTabChange(eventData: any, className: string, nextElementTabIndex?: number, preElementTabIndex?: number) {
    let nextControlTabIndex;
    if (eventData.shiftKey) {
      nextControlTabIndex = preElementTabIndex
        ? preElementTabIndex
        : Number(eventData.target.tabIndex - 1);
    } else {
      nextControlTabIndex = nextElementTabIndex
        ? nextElementTabIndex
        : Number(eventData.target.tabIndex + 1);
    }
    let nextElement: any = this.getElement(nextControlTabIndex, className);
    if (nextElement) {
      if (nextElement.disabled) {
        if (eventData.shiftKey) {
          nextElement = this.getElement(preElementTabIndex, className);
        } else {
          nextElement = this.getElement(nextElementTabIndex, className);
        }
      }
      if (nextElement) {
        eventData.target.blur();
        nextElement.focus();
        nextElement.select();
        eventData.preventDefault();
      }
    }

  }
  getElement(controlTabIndex: number, className: string) {
    const elementList = document.getElementsByClassName(className);
    for (let index = 0; index < elementList.length; index++) {
      const element = (<HTMLElement>elementList[index]);
      const nextElement = this.tabIndexOfElement(element);
      if (nextElement.tabIndex === controlTabIndex) {
        return nextElement;
      }
    }
  }
  tabIndexOfElement(elementDetails: HTMLElement): HTMLElement {
    switch (elementDetails.tagName.toUpperCase()) {
      case 'KENDO-COMBOBOX':
        return (<HTMLElement>elementDetails.children[0].children[0].children[0]);
      case 'KENDO-DATEPICKER':
        return (<HTMLElement>elementDetails.children[0].children[0].children[0].children[0]);
      case 'KENDO-NUMERICTEXTBOX':
        return (<HTMLElement>elementDetails.children[0].children[0]);
      case 'KENDO-MASKEDTEXTBOX':
        return (<HTMLElement>elementDetails.children[0]);
      case 'KENDO-TIMEPICKER':
      return (<HTMLElement>elementDetails.children[0].children[0].children[0].children[0]);
      default:
        return elementDetails;
    }
  }

  public gracefullyTransformDateTime(dateVal: Date, format: string) {
    if (typeof dateVal === 'string') {
      return dateVal;
    }
    dateVal = dateVal && dateVal instanceof Date ? dateVal : new Date(0);
    try {
      return this.datePipe.transform(dateVal, format);
    } catch {
      return dateVal.getFullYear() + '-' + (dateVal.getMonth() + 1) + '-' + dateVal.getDate()
      + ' ' + dateVal.getHours() + ':' + dateVal.getMinutes() + ':' + dateVal.getSeconds();
    }
  }

}
