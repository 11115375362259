import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Injectable({
    providedIn: 'root'
  })
export class AnalyticsService {

public colorado = this.configService.readConfig().colorado;
public googleTag;
  constructor(private configService: ConfigService,) {
    this.colorado? this.googleTag = this.configService.readConfig().gtagColorado : this.googleTag = this.configService.readConfig().gtag;
  }

  public loadGoogleAnalytics(): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ this.googleTag }`);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag
    (\'js\', new Date());gtag(\'config\', \'${ this.googleTag }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }
}
