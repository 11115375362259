import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IKendoGridColumnSetting } from '../common-grid/IKendoGridColumnSetting';
import { CommonConstants } from '../constants/common-constants';
import { ValidationFunctions } from '../utilities/common-validation-functions';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'CDMS-common-grid-modal-control',
    templateUrl: './common-grid-modal-control.component.html',
    styleUrls: ['./common-grid-modal-control.component.scss'],
})

export class CommonGridModalControlComponent implements OnInit {
    @Input() show = false;
    @Input() title = '';
    @Input() columns: Array<IKendoGridColumnSetting>;
    @Input() headers: {
        label: string;
        value: string;
    }[];
    @Input() formGroup: FormGroup;
    @Input() errorMessage = CommonConstants.errorMessages;
    @Input() isNew = true;
    @Input() editEvent: any;
    @Input() onLoad: Function;

    @Output() updateEvent = new EventEmitter();
    @Output() saveEvent = new EventEmitter();
    @Output() cancelEvent = new EventEmitter();

    public commonConstants = CommonConstants;
    public modalSize = 'common-modal-medium';
    public loading = true;
    public showConfirm = false;
    public emptyControl: FormControl = new FormControl();

    constructor() {
    }

    ngOnInit() {
        // Set modal column logic if needed
        if (this.isNew === false) {
            this.columns.forEach(x => {
                if (x.type === 'lookup') {
                    x.manualDisplayValue = (this.editEvent)
                    ? this.editEvent?.dataItem[x.field]
                    : x.manualDisplayValue;
                }
            });
        }
        // Set modal title
        this.title = (this.isNew)
        ? 'Create ' + (this.title ?? '')
        : 'Update ' + (this.title ?? '');
        // Set modal size
        let colCount = 0;
        this.columns.forEach(x => {
            if (x.modalHide !== true) { colCount++; }
        });
        if (colCount < 6) {
            this.modalSize = 'common-modal-small';
        } else if (colCount > 12) {
            this.modalSize = 'common-modal-large';
        }
        this.formGroup.markAsUntouched();
        // Call onLoad function if available
        if (this.onLoad) { this.onLoad(); }
    }

    public save() {
        if (this.formGroup) {
            this.columns.forEach(col => {
                if (col.resetValidation) {
                    col.resetValidation(this.formGroup.controls, this.formGroup.controls[col.field].value);
                }
            });
            this.formGroup.updateValueAndValidity();
            if (this.checkValid()) {
                (this.isNew)
                ? this.saveEvent.emit(this.formGroup)
                : this.updateEvent.emit(this.formGroup);
                this.cleanup();
            }
        }
    }

    public close() {
        this.cancelEvent.emit();
        this.cleanup();
    }

    public checkConfirm() {
        if (this.formGroup.touched) {
            this.showConfirm = true;
        } else {
            this.close();
        }
    }

    public closeConfirm(str) {
        this.showConfirm = false;
        if (str.toLowerCase() === 'continue') {
            this.close();
        }
    }

    private checkValid() {
        for (const control in this.formGroup.controls) {
            if (this.formGroup.controls[control]) {
                this.formGroup.controls[control].markAsTouched();
            }
        }
        for (const control in this.formGroup.controls) {
            if (this.formGroup.controls[control].invalid) {
                return false;
            }
        }
        return true;
    }

    private cleanup() {
        this.show = false;
        this.columns.forEach(x => {
            if (x.type === 'lookup') {
                x.manualDisplayValue = undefined;
            }
        });
    }

    public setInputWidth(column) {
        switch (column.modalWholeLine) {
            case 'right': {
                return 'modal-control-whole-line-right';
            }
            case 'right-buttons': {
                return 'modal-control-whole-line-right-buttons';
            }
            case 'left': {
                return 'modal-control-whole-line-left';
            }
            case 'center': {
                return 'modal-control-whole-line-center';
            }
            default: {
                return 'input-field';
            }
        }
    }

    public getFalse() {
        return false;
    }

    public checkRequired(column) {
        if (column.validators) {
            return Array.prototype.includes.call(column?.validators, ValidationFunctions.required);
        }
        return false;
    }

    public getDisplayValue(column: IKendoGridColumnSetting) {
        const value = this.formGroup.controls[column.field].value;
        if (value === null || value === undefined) {
            if (column.changeValueToDefaultConditional !== undefined && column.changeValueToDefaultConditional(value)
            && column.defaultDisplayValue !== null && column.defaultDisplayValue !== undefined) {
                return column.defaultDisplayValue;
            }
            return null;
        }
        return value;
    }

    public multiSelectChangeEvent(value: any, formcontrolName: string) {
        if (value) {
            this.formGroup.controls[formcontrolName].setValue(value);
        }
    }

}
