<!--  Save / Edit Modal -->
<div *ngIf="show" class="modal-wrapper">
  <kendo-dialog
      *ngIf="show"
      [title]="title"
      class="common-modal"
      [ngClass]="modalSize"
      (close)="close()"
      [minWidth]="400">

    <!-- Headers -->
    <ng-container *ngIf="headers !== undefined">
      <ng-container *ngFor="let header of headers">
        <div class="common-modal-header">
          <span class="common-modal-header-label">{{header.label}}</span>
          <span class="common-modal-header-value">{{header.value}}</span>
        </div>
      </ng-container>
      <div class="common-modal-horizontal-rule"></div>
    </ng-container> 

    <!--  Input Fields -->
    <ng-container *ngFor="let column of columns">
      <div
          *ngIf="(column.modalHide !== true)
            && !(column.modalHideEdit === true && editEvent !== undefined)
            && !(column.modalHideCreate === true && editEvent === undefined)"
          class= "{{setInputWidth(column)}}">
        <ng-container [ngSwitch]="column.type">

          <!-- Input Field Type Cases -->
          <div *ngSwitchCase="'text'">
            <ng-container *ngTemplateOutlet="text"></ng-container>
          </div>
          <div *ngSwitchCase="'numeric'">
            <ng-container *ngTemplateOutlet="numeric"></ng-container>
          </div>
          <div *ngSwitchCase="'boolean'">
            <ng-container *ngTemplateOutlet="boolean"></ng-container>
          </div>
          <div *ngSwitchCase="'date'">
            <ng-container *ngTemplateOutlet="date"></ng-container>
          </div>
          <div *ngSwitchCase="'datetime'">
            <ng-container *ngTemplateOutlet="datetime"></ng-container>
          </div>
          <div *ngSwitchCase="'select'">
            <ng-container *ngTemplateOutlet="combobox"></ng-container>
          </div>
          <div *ngSwitchCase="'lookup'">
            <ng-container *ngTemplateOutlet="lookup"></ng-container>
          </div>
          <div *ngSwitchCase="'multiSelect'">
            <ng-container *ngTemplateOutlet="multiSelect"></ng-container>
          </div>
          <div *ngSwitchCase="'command'"></div>
          <div *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="text"></ng-container>
          </div>

          <!--  Input Field Templates -->
          <ng-template #text>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-textbox-container *ngIf="!column.inputMask">
                  <input
                      kendoTextBox
                      class="modal-control"
                      title="{{column.title}}"
                      placeholder="{{column.placeholder}}"
                      value="{{editEvent?.dataItem[column.field]}}"
                      (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                      (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                      [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"/>
                </kendo-textbox-container>
                <kendo-maskedtextbox
                    *ngIf="column.inputMask"
                    class="modal-control"
                    title="{{column.title}}"
                    placeholder="{{column.placeholder}}"
                    [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                    value="{{editEvent?.dataItem[column.field]}}"
                    (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                    (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                    [mask]="column.inputMask">
                </kendo-maskedtextbox>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #boolean>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-textbox-container class="checkbox-container">
                  <input
                      kendoCheckBox
                      type="checkbox"
                      title="{{column.title}}"
                      placeholder="{{column.placeholder}}"
                      value="{{editEvent?.dataItem[column.field]}}"
                      (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                      (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                      [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"/>
                </kendo-textbox-container>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #numeric>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-numerictextbox
                    (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                    (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                    class="modal-control"
                    title="{{column.title}}"
                    placeholder="{{column.placeholder}}"
                    [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                    [format]="
                      column?.attributes !== null
                        ? 'n'.concat(column?.attributes['fixedDecimal'] !== null
                          ? column?.attributes['fixedDecimal']
                          : '2')
                        : null">
                </kendo-numerictextbox>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #date>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-datepicker
                    class="modal-control"
                    title="{{column.title}}"
                    (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                    (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                    [disabledDates]="column?.disabledDates"
                    [disabledDatesValidation]="column?.disabledDatesValidation"
                    [readOnlyInput]="column?.readOnlyInput"
                    [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                    [format]="'MM/dd/yyyy'"
                    placeholder="MM/dd/yyyy"
                    [min]="commonConstants.minSmallDate"
                    [max]="commonConstants.maxSmallDate">
                </kendo-datepicker>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #datetime>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-datepicker
                    (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                    (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                    class="modal-control"
                    title="{{column.title}}"
                    placeholder="{{column.placeholder}}"
                    [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                    [format]="'MM/dd/yyyy - HH:mm'"
                    placeholder="MM/dd/yyyy"
                    [min]="commonConstants.minSmallDateTime"
                    [max]="commonConstants.maxSmallDateTime">
                </kendo-datepicker>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #combobox let-dataItem>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-combobox
                    [data]="column.options"
                    [itemDisabled]="column?.setDisabledOptions ? column.setDisabledOptions : getFalse"
                    value="{{editEvent?.dataItem[column.field]}}"
                    (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                    (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                    class="modal-control"
                    title="{{column.title}}"
                    placeholder="{{column.placeholder}}"
                    [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                    [readonly]="column?.controlDisabled"
                    [valueField]="column.valueField"
                    [textField]="column.textField">
                </kendo-combobox>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #lookup>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <div class="lookup-modal-control">
                  <input
                      type="text"
                      class="modal-control"
                      title="{{column.title}}"
                      placeholder="{{column.placeholder}}"
                      [ngModel]="column?.manualDisplayValue"
                      (ngModelChange)="
                        column?.controlChangeEvent
                          ? column.controlChangeEvent($event, formGroup)
                          : undefined"
                      value="
                        column?.manualDisplayValue
                          ? column.manualDisplayValue
                          : undefined"
                      (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent(this) : undefined"
                      (click)="column?.controlClickEvent ? column.controlClickEvent(this) : undefined"
                      [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                      readonly>
                  <a
                      kendoButton
                      (click)="column.controlClickEvent()"
                      class="lookup-icon">
                  </a>
                </div>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template #multiSelect>
            <div class="common-modal-control-wrapper">
              <div class="common-modal-control-content">
                <div class="label-content">
                  <span class="label">{{column.title}}</span><!--
                  --><ng-container *ngIf="checkRequired(column)"><span class="label d-inline-block">*</span></ng-container>
                </div>
                <kendo-multiselect
                    class="modal-control"
                    [data]="column.options"
                    [value]="getDisplayValue(column)"
                    [valueField]="column.valueField"
                    [textField]="column.textField"
                    (valueChange)="multiSelectChangeEvent($event, column.field)"
                    title="{{column.title}}"
                    placeholder="{{column.placeholder}}"
                    [formControl]="column?.field ? $any(formGroup).get(column.field) : emptyControl"
                    [readonly]="column?.controlDisabled">
                </kendo-multiselect>
                <ng-container *ngTemplateOutlet="error"></ng-container>
              </div>
            </div>
          </ng-template>

          <!-- Error Template -->
          <ng-template #error>
            <span class="error">
              <ng-container
                  *ngIf="formGroup.controls[column.field]?.errors
                    && formGroup.controls[column.field]?.invalid
                    && formGroup.controls[column.field]?.touched">
                <ng-container *ngFor="let error of formGroup.controls[column.field].errors | keyvalue">
                  {{errorMessage[error.key]}}
                </ng-container>
              </ng-container>
            </span>
          </ng-template>

        </ng-container>
      </div>
    </ng-container>

    <!-- Bottom Button Bar -->
    <div class="btm-btn-bar">
      <kendo-dialog-actions>
        <button
            kendoButton
            type="button"
            id="saveBtnCommonModal"
            class="cdms-btn-blue k-button modal-btn modal-save-btn"
            (click)="save()">
          {{(isNew) ? "Save" : "Update"}}
        </button>
        <button
            kendoButton
            type="button"
            id="cancelBtnCommonModal"
            class="cdms-btn-grey k-button modal-btn modal-cancel-btn"
            (click)="checkConfirm()">
          Cancel
        </button>
      </kendo-dialog-actions>
    </div>
  </kendo-dialog>
</div>

<CDMS-Portal-U2-common-confirmation-modal-control
  class="center"
  [modalType]="'continue'"
  [showDialog]="showConfirm"
  [title]="'Are you sure?'"
  [bodyText]="'This action will lose your unsaved changes, do you want to continue?'"
  (modalEvent)="closeConfirm($event)">
</CDMS-Portal-U2-common-confirmation-modal-control>
