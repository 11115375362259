import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UploadModule } from '@progress/kendo-angular-upload';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { CommonComponentsModule } from './common-components/common-components.module';
import { ComponentsModule } from './components/components.module';
import { PROVIDERDIRECTORY_API_BASE_URL } from './services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { AppbarService } from './components/appbar/appbar-service';
import { AttendantAuthGuard, AdminAuthGuard } from './services/auth-guards.service';
import { IsDeletedFilter } from './components/pipes/isdeleted-filter.pipe';
import { LangTransform } from './components/pipes/langtransform.pipe';

import { AdminAttendantsPage } from './pages/admin/admin-attendants/admin-attendants.page';
import { AdminAccountsPage } from './pages/admin/admin-accounts/admin-accounts.page';
import { AttendantSearchPage } from './pages/search/attendant-search.page';
import { AttendantProfilePage } from './pages/attendant/attendant-profile/attendant-profile.page';
import { AttendantProfileEditPage } from './pages/attendant/attendant-profile-edit/attendant-profile-edit.page';
import { LoginPage } from './pages/login/login.page';
import { RegisterPage } from './pages/register/register.page';
import { RequirementsPage } from './pages/requirements/requirements.page';
import { HomePage } from './pages/home/home.page';
import { AttendantAffirmPage } from './pages/attendant/attendant-affirm/attendant-affirm.page';
import { AttendantReaffirmPage } from './pages/attendant/attendant-reaffirm/attendant-reaffirm.page';

/** Import section for Authn and Authz */
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG,
  MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './app-msal-extensions';
import MsalServiceWrapper from './services/msal.wrapper.service';
import { ConfigService } from './services/config.service';
import { InterceptorService } from './services/http-interceptor.service';

/* eslint arrow-body-style: "off" */
const appInitializerFn = (configService: ConfigService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  declarations: [
    HomePage,
    AppComponent,
    AdminAttendantsPage,
    AdminAccountsPage,
    AttendantSearchPage,
    AttendantProfilePage,
    AttendantProfileEditPage,
    AttendantAffirmPage,
    AttendantReaffirmPage,
    LoginPage,
    RegisterPage,
    RequirementsPage,
    IsDeletedFilter,
    LangTransform,
  ],
  entryComponents: [
  ],
  imports: [
    NotificationModule,
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CommonComponentsModule,
    ComponentsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    UploadModule,
    TreeViewModule,
    LayoutModule,
    ListViewModule,
    PopupModule,
    DialogsModule,
    IndicatorsModule,
    MsalModule
  ],
  exports: [
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    DatePipe,
    AppbarService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService]
    },
    AttendantAuthGuard,
    MsalServiceWrapper,
    AdminAuthGuard,
    {
      provide: PROVIDERDIRECTORY_API_BASE_URL,
      useFactory: () => environment.providerDirectoryApiHost
    }],
  bootstrap: [
    AppComponent, MsalRedirectComponent
  ],
})
export class AppModule {}
