import { DistanceType } from '../../services/providerdirectoryapi/providerdirectoryfunctionapi.services';

export interface IAttendantSearch {
    locationToCheck_Lat?: number;
    locationToCheck_Long?: number;
    radius?: number;
    zipCode?: number;
    hours?: number;
    day?: string;
    distanceType?: DistanceType;
}

export class AttendantSearch implements IAttendantSearch {
    locationToCheck_Lat?: number | undefined;
    locationToCheck_Long?: number | undefined;
    radius?: number | undefined;
    zipCode?: number | undefined;
    hours?: number | undefined;
    day?: string | undefined;
    distanceType?: DistanceType | undefined;

    constructor(data?: IAttendantSearch) {
        if (data !== undefined) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.locationToCheck_Lat = _data['locationToCheck_Lat'];
            this.locationToCheck_Long = _data['locationToCheck_Long'];
            this.radius = _data['radius'];
            this.zipCode = _data['zipCode'];
            this.hours = _data['hours'];
            this.day = _data['day'];
            this.distanceType = _data['distanceType'];
        }
    }

    static fromJS(data: any): AttendantSearch {
        data = typeof data === 'object' ? data : {};
        const result = new AttendantSearch();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['locationToCheck_Lat'] = this.locationToCheck_Lat;
        data['locationToCheck_Long'] = this.locationToCheck_Long;
        data['radius'] = this.radius;
        data['zipCode'] = this.zipCode;
        data['hours'] = this.hours;
        data['day'] = this.day;
        data['distanceType'] = this.distanceType;
        return data;
    }
}
