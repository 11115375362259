<div class="page-wrapper">
    <div class="column-center">

        <!-- BANNER IMAGE -->
        <div class="banner">
        </div>

        <!-- CENTER TITLE -->
        <div *ngIf="colorado" class="column-center">
            <p class="page-title m-t-20">{{'page_HomeCO_Title' | langchange}}</p>
            <div>
              <p class="page-text m-t-10 textbox-large">{{'page_HomeCO_Description' | langchange}}</p>
              <p class="page-text m-t-10 textbox-large">{{'page_HomeCO_Paragraph1' | langchange}}</p>
              <p class="page-text m-t-10 textbox-large">{{'page_HomeCO_Paragraph2' | langchange}}</p>
              <p class="page-text m-t-10 textbox-large">{{'page_HomeCO_Paragraph3' | langchange}}</p>
            </div>
        </div>
        <div *ngIf="!colorado" class="column-center">
            <p class="page-title m-t-20">{{'page_Home_Title' | langchange}}</p>
            <p class="page-text m-t-10 textbox-large">{{'page_Home_p1' | langchange}}</p>
            <div>
                <p class="page-subtitle-1 m-t-10 textbox-large">{{'page_Home_p4' | langchange}}</p>
                <p class="page-text m-t-10 textbox-large">{{'page_Home_p5' | langchange}}</p>
                <div class="flex-end-row">
                    <p class="page-text m-t-20 textbox-large">{{'page_Home_p6' | langchange}}&nbsp;
                        <a href="https://consumerdirectcare.com/"
                        title="{{'page_Home_p7' | langchange}}"
                        class="m-t-10">
                        {{'page_Home_p7' | langchange}}
                        </a>
                    </p>
                </div>
            </div>
        </div>

        <!-- ATTENDANT SEARCH SECTION -->
        <div class="page-section m-t-60 flex-col">
            <div class="row">
                <p *ngIf="colorado" class="page-subtitle-1">{{'page_HomeCO_FindAttendants' | langchange}}</p>
                <p *ngIf="!colorado" class="page-subtitle-1">{{'page_Home_FindCaregivers' | langchange}}</p>
                <div *ngIf="colorado">
                    <CDMS-common-info-slideout-control
                        text="{{'page_Home_SearchInfoCO' | langchange}}"
                        [position]="'right'"
                        [iconSize]="'medium'"
                        [slideoutSize]="'large'">
                    </CDMS-common-info-slideout-control>
                </div>
                <div *ngIf="!colorado">
                    <CDMS-common-info-slideout-control
                        text="{{'page_Home_SearchInfo' | langchange}}"
                        [position]="'right'"
                        [iconSize]="'medium'"
                        [slideoutSize]="'large'">
                    </CDMS-common-info-slideout-control>
                </div>
            </div>
            <form
                class="row-center tab"
                [formGroup]="searchForm">
                <div class="m-r-20">
                    <div>
                        <CDMS-common-input-control
                            title="{{'title_Zipcode' | langchange}}"
                            [control]="$any(this.searchForm?.get('zipCode'))"
                            placeholder="XXXXX"
                            ngDefaultControl
                            formControlName="zipCode"
                            [input]="'text'"
                            [size]="'small'"
                            [value]="searchData.zipCode">
                        </CDMS-common-input-control>
                    </div>
                </div>
                <div class="m-r-20">
                    <div>
                        <CDMS-common-input-control
                            title="{{'title_Distance' | langchange}}"
                            placeholder="{{'title_Select' | langchange}}"
                            [control]="$any(this.searchForm?.get('radius'))"
                            ngDefaultControl
                            formControlName="radius"
                            [input]="'dropdown'"
                            [size]="'small'"
                            [options]="radiusOptions"
                            [value]="searchData.radius">
                        </CDMS-common-input-control>
                    </div>
                </div>
                <div>
                    <div>
                        <CDMS-common-button-control
                            title="{{'title_Search' | langchange}}"
                            [titleHidden]="true"
                            [button]="'icon'"
                            [icon]="'zoom'"
                            [size]="'medium'"
                            [type]="'primary'"
                            [plane]="'vertical'"
                            [disabled]="searchForm.invalid"
                            (clickEvent)="goSearch()">
                        </CDMS-common-button-control>
                    </div>
                </div>
            </form>
        </div>

        <!-- ATTENDANT REGISTRATION SECTION -->
        <div class="page-section m-t-40">
            <div *ngIf="!colorado">
                <p class="page-subtitle-1 m-t-10 textbox-large">{{'page_Home_p2' | langchange}}</p>
                <p class="page-text m-t-10 textbox-large">{{'page_Home_p3' | langchange}}</p>
            </div>
            <p *ngIf="colorado" class="page-subtitle-1 m-t-20 ">{{'page_HomeCO_BecomeAttendant' | langchange}}</p>
            <p *ngIf="!colorado" class="page-subtitle-2 m-t-20 ">{{'page_Home_RegisterWithUs' | langchange}}</p>
            <p *ngIf="colorado" class="page-text m-t-10 textbox-large">{{'page_HomeCO_Requirements_Paragraph' | langchange}}</p>
            <p *ngIf="!colorado" class="page-text m-t-10 textbox-large">{{'page_Home_Requirements_Paragraph' | langchange}}</p>
            <div class="row-center m-t-40">
                <p *ngIf="colorado" class="page-text">{{'page_HomeCO_Requirements_p1' | langchange}}&nbsp;</p>
                <a
                    *ngIf="colorado"
                    title="{{'page_HomeCO_Requirements_p2' | langchange}}"
                    (click)="goRequirements()"
                    class="link">
                    {{'page_HomeCO_Requirements_p2' | langchange}}
                </a>
                <p *ngIf="colorado" class="page-text m-r-20">&nbsp;{{'page_HomeCO_Requirements_p3' | langchange}}</p>
                <div class="">
                    <CDMS-common-button-control
                        title="{{'title_Register' | langchange}}"
                        [button]="'standard'"
                        [size]="'medium'"
                        [type]="'primary'"
                        (clickEvent)="goRegister()">
                    </CDMS-common-button-control>
                </div>
            </div>
        </div>

        <!-- LOGO BUTTONS -->
        <div class="center-column">
            <div class="center-buttons logo-row m-t-60">
                <div class="logo m-r-20">
                    <a href="{{colorado ? 'https://consumerdirectco.com/' : 'https://consumerdirectcare.com/'}}"
                        title="Go to Consumer Direct"
                        class="{{colorado ? 'CDCO-logo-image' : 'CDCO-logo-image-all'}}"
                        type="button">
                    </a>
                </div>
                <div
                    *ngIf="colorado"
                    class="logo m-r-20">
                    <a href="https://hcpf.colorado.gov/consumer-directed-attendant-support-services "
                        title="Go to Colorado Department of Health Care Policy & Financing"
                        class="HCPF-logo-image"
                        type="button">
                    </a>
                </div>
                <!-- TRADEMARK STATEMENT-->
                <div class="page-section"></div>
                <div class="page-section flex-center-col">
                    <p *ngIf="colorado" class="page-text textbox-large">{{'page_HomeCO_Trademark' | langchange}}</p>
                    <p *ngIf="!colorado" class="page-text textbox-large">{{'page_Home_Trademark' | langchange}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- CAPTCHA-->
<div *ngIf="showCaptcha">
    <div class="captcha-wrapper">
        <CDMS-captcha-control
            (errorEvent)="captchaResponse(false, $event)"
            (resolved)="captchaResponse(true, $event)">
        </CDMS-captcha-control>
      </div>
</div>
