/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, SilentRequest } from '@azure/msal-browser';
import { b2c_config, environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signUpSignIn: b2c_config.signin_signup_policy,
        signUp: b2c_config.signup_policy,
        changeSignInName: b2c_config.change_signin_name_policy,
        passwordReset: b2c_config.password_reset_policy,
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${b2c_config.authority}/${b2c_config.directory}/${b2c_config.signin_signup_policy}`,
        },
        signUp: {
            authority: `https://${b2c_config.authority}/${b2c_config.directory}/${b2c_config.signup_policy}`,
        },
        changeSignInName: {
            authority: `https://${b2c_config.authority}/${b2c_config.directory}/${b2c_config.change_signin_name_policy}`,
        },
        passwordReset: {
            authority: `https://${b2c_config.authority}/${b2c_config.directory}/${b2c_config.password_reset_policy}`,
        }
    },
    authorityDomain: b2c_config.authority
  };

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
 export const msalConfig: Configuration = {
    auth: {
        clientId: b2c_config.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/'// Indicates the page to navigate after logout.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                if(!environment.production)
                    {console.log(message);}
            },
            logLevel: environment.production ? LogLevel.Info : LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
};

export const silentRequest: SilentRequest = {
    scopes: [ `https://${b2c_config.directory}/${b2c_config.appIdURI}/access_as_user`],
    authority: b2cPolicies.authorities.signUpSignIn.authority,
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const loginRequest = {
    scopes: [ `https://${b2c_config.directory}/${b2c_config.appIdURI}/access_as_user`]
  };

 export const protectedResources = {
    providerDirectoryAdmin: {
        endpoint: `${environment.providerDirectoryApiHost}/api/Admin`,
        scopes: loginRequest.scopes
    },
    providerDirectoryAttendant: {
        endpoint: `${environment.providerDirectoryApiHost}/api/Attendant`,
        scopes: loginRequest.scopes
    },
  };
