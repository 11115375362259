import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FileRestrictions, UploadComponent } from '@progress/kendo-angular-upload';
import { AppConstants } from 'src/app/constants/app-constants';

import { Upload } from '../../constants/app-types';
import { Notification } from '../services/common-notification.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'CDMS-common-upload-control',
    templateUrl: './common-upload-control.component.html',
    styleUrls: ['../assets/scss/_common-components.scss'],
})

export class CommonUploadControlComponent implements OnInit {
    @Input()
    public set type(data: Upload) {
        this._type = data;

        switch(this._type){
            case 'resume':
                this.fileRestrictions = {
                    allowedExtensions: ['pdf'],
                    maxFileSize: undefined,
                    minFileSize: undefined,
                };
                break;
            case 'picture':
                this.fileRestrictions = {
                    allowedExtensions: ['jpg', 'jpeg', 'png'],
                    maxFileSize: undefined,
                    minFileSize: undefined,
                };
                break;
            default:
                this.fileRestrictions = {
                    allowedExtensions: ['pdf'],
                    maxFileSize: undefined,
                    minFileSize: undefined,
                };
                break;
        }
    }
    public get type() {
        return this._type;
    }

    @Input() show = true;
    @Input() disabled = false;
    @Input() autoUpload = false;

    @Output() upload = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() clear = new EventEmitter();
    @Output() completeEvent = new EventEmitter();
    @Output() errorEvent = new EventEmitter();
    @Output() successEvent = new EventEmitter();

    @ViewChild('upload', { static: true }) uploadComponent: UploadComponent;

    private _type: Upload = 'resume';

    public fileRestrictions: FileRestrictions = {
        allowedExtensions: ['pdf'],
        maxFileSize: undefined,
        minFileSize: undefined,
    };
    public lang = AppConstants.selectedLanguage;

    constructor(
        public toastr: Notification,
    ) { }

    ngOnInit() {
      this.lang = AppConstants.selectedLanguage;
    }

    public onUpload(event): void {
        this.upload.emit(event);
    }

    public onCancel(): void {
        this.cancel.emit();
    }

    public onClear(): void {
        this.clear.emit();
    }

    public onComplete(): void {
        this.completeEvent.emit();
    }

    public onError(): void {
        this.errorEvent.emit();
        // this.toastr.error('There was an error uploading');
    }

    public onSuccess(): void {
        this.successEvent.emit();
        // this.toastr.success('Successfully uploaded');
    }
}
