import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS  } from 'ng-recaptcha';

import { AppbarControlComponent } from './appbar/appbar-control.component';
import { WeeklyWorkScheduleControlComponent } from './weekly-work-schedule/weekly-work-schedule-control.component';
import { CaptchaControlComponent } from './captcha/captcha-control.component';
import { StatusContainerControl } from './status-container/status-container-control.component';
import { AttendantCardControl } from './attendant-card/attendant-card-control.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { StatusIndicatorControl } from './status-indicator/status-indicator-control.component';
import { LangchangePipe } from './pipes/langchange.pipe';
import { captcha } from 'src/environments/environment';

// We need to sign up for google captcha and get codes of our own: https://developers.google.com/recaptcha
const captchaSettings: RecaptchaSettings = { siteKey: captcha.siteKey };
@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        DialogsModule,
        TooltipModule,
        GridModule,
        ExcelModule,
        ButtonsModule,
        InputsModule,
        LayoutModule,
        DropDownsModule,
        DateInputsModule,
        CommonComponentsModule,
        UploadsModule,
        RecaptchaModule,
    ],
    declarations: [
        AppbarControlComponent,
        WeeklyWorkScheduleControlComponent,
        CaptchaControlComponent,
        StatusContainerControl,
        AttendantCardControl,
        StatusIndicatorControl,
        LangchangePipe,
    ],
    exports: [
        AppbarControlComponent,
        WeeklyWorkScheduleControlComponent,
        CaptchaControlComponent,
        StatusContainerControl,
        AttendantCardControl,
        StatusIndicatorControl,
        LangchangePipe,
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: captchaSettings,
        },
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule {}
