import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import MsalServiceWrapper from 'src/app/services/msal.wrapper.service';
import { Router } from '@angular/router';

import { AppbarService } from '../../components/appbar/appbar-service';
import { ConfigService } from 'src/app/services/config.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-register',
  templateUrl: 'register.page.html',
  styleUrls: ['register.page.scss'],
})
export class RegisterPage implements OnInit {

  public termsData = {
    agree: false,
  };
  public termsForm: FormGroup;
  public showTermsModal = false;
  public showFullTermsModal = false;
  public colorado = this.configService.readConfig().colorado;
  public showLogoutModal = false;

  constructor(
    private appbarService: AppbarService,
    private msalService: MsalServiceWrapper,
    private router: Router,
    private configService: ConfigService,
    private authService: MsalService,
    ) {}

    ngOnInit(): void {
      this.setPage();
      this.setForm();
    }

    ionViewWillEnter(): void {
      this.setPage();
    }

    public setPage() {
      this.appbarService.setPage('registration');
    }

    public setForm() {
      this.termsForm = new FormGroup({
        agree: new FormControl(this.termsData.agree),
      });
    }

    public goSignup() {
      const isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
      if (isLoggedIn) {
        this.showLogoutModal = true;
      } else {
        this.msalService.initiateFlow('SignUp');
      }
    }

    public goBack() {
      this.router.navigate(['/Home']);
    }

    public showFullTerms() {
      this.showFullTermsModal = true;
    }

    public closeFullTermsModal() {
      this.showFullTermsModal = false;
    }

    public closeChangesModal(data: 'yes' | 'no') {
      if (data === 'yes') {
        this.authService.logout();
      }
      this.showLogoutModal = false;
    }
}
