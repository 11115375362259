<div class="page-wrapper">
    <div class="column">
        <form [formGroup]="searchForm">

            <!-- SEARCH FIELDS -->
            <div class="row search-fields">
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Status"
                            [control]="$any(this.searchForm.get('status'))"
                            ngDefaultControl
                            formControlName="status"
                            [input]="'dropdown'"
                            [size]="'medium'"
                            [options]="profileStatusOptions"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [value]="searchData.status">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="First Name"
                            [control]="$any(this.searchForm.get('firstName'))"
                            ngDefaultControl
                            formControlName="firstName"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.firstName">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Last Initial"
                            [control]="$any(this.searchForm.get('lastName'))"
                            ngDefaultControl
                            formControlName="lastName"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.lastName">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="ID"
                            [control]="$any(this.searchForm.get('id'))"
                            ngDefaultControl
                            formControlName="id"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.id">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Email"
                            [control]="$any(this.searchForm.get('email'))"
                            ngDefaultControl
                            formControlName="email"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.email">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Zipcode"
                            [control]="$any(this.searchForm.get('zipcode'))"
                            ngDefaultControl
                            formControlName="zipcode"
                            [input]="'text'"
                            [size]="'medium'"
                            [value]="searchData.zipcode">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="State"
                            [control]="$any(this.searchForm.get('state'))"
                            ngDefaultControl
                            formControlName="state"
                            [input]="'dropdown'"
                            [size]="'small'"
                            [options]="stateOptions"
                            [value]="searchData.state">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
                <div class="search-field" [hidden]="true">
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Certifications"
                            [control]="$any(this.searchForm.get('certifications'))"
                            ngDefaultControl
                            formControlName="certifications"
                            [input]="'multiselect'"
                            [size]="'medium'"
                            [options]="certOptions"
                            [textField]="'name'"
                            [valueField]="'id'"
                            [value]="searchData.certifications"
                            (valueChange)="setCerts($event)">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
            </div>

            <!-- SEARCH BUTTONS -->
            <div class="row search-buttons">
                <div class="search-field">
                    <CDMS-common-button-control
                        title="Clear"
                        [button]="'standard'"
                        [size]="'small'"
                        [type]="'secondary'"
                        (clickEvent)="clear()">
                    </CDMS-common-button-control>
                </div>
                <div>
                    <CDMS-common-button-control
                        title="Search"
                        [button]="'standard'"
                        [size]="'small'"
                        [type]="'primary'"
                        (clickEvent)="search()">
                    </CDMS-common-button-control>
                </div>
            </div>
        </form>

        <!-- EMAIL BUTTON -->
        <form [formGroup]="emailForm">
            <div class="row-center grid-buttons"> 
                <div class="search-field">
                    <CDMS-common-button-control
                        title="Email"
                        [button]="'standard'"
                        [size]="'small'"
                        [type]="'primary'"
                        [disabled]="!(selections?.length > 0)"
                        (clickEvent)="email()">
                    </CDMS-common-button-control>
                </div>
                <div>
                    <kendo-formfield>
                        <CDMS-common-input-control
                            title="Email Type"
                            [control]="$any(this.emailForm.get('email'))"
                            ngDefaultControl
                            formControlName="email"
                            [input]="'dropdown'"
                            [size]="'medium'"
                            [options]="emailOptions"
                            [value]="emailData.email">
                        </CDMS-common-input-control>
                    </kendo-formfield>
                </div>
            </div>
        </form>

        <!-- GRID -->
        <div class="row grid">
            <CDMS-common-grid-control
                [title]="'Attendant Accounts'"
                [kendoGridBindingData]="searchResults"
                [columns]="columns"
                [scrollable]="'scrollable'"
                [loading]="gridLoading"
                [sort]="state.sort"
                [filter]="state.filter"
                [skip]="state.skip"
                [showCheckBoxCol]="true"
                [showCheckBoxColSelectAll]="true"
                [selectable]="true"
                (multiselectionChangeData)="setSelected($event)"
                (hyperlink)="goAttendantProfile($event)">
            </CDMS-common-grid-control>
        </div>

    </div>
</div>