import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../controllers/user/user.controller';
import { AppbarService } from '../../components/appbar/appbar-service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
})
export class LoginPage implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private appbarService: AppbarService,
  ) {
  }

  ngOnInit(): void {
    this.setPage();
  }

  ionViewWillEnter(): void {
    this.setPage();
  }

  public setPage() {
    this.appbarService.setPage('login');
  }
}
