import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { Modal } from '../constants/common-type-constants';
import { CommonConstants } from '../constants/common-constants';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'CDMS-common-confirmation-modal-control',
    templateUrl: './common-confirmation-modal-control.component.html',
    styleUrls: ['./common-confirmation-modal-control.component.scss', '../assets/scss/_common-components.scss'],
})
export class CommonConfirmationModalControlComponent implements OnInit {

    @Input()
    public set modalType(data: Modal) {
        this._modalType = data;
        this.setModalType();
    }
    public get modalType() {
        return this._modalType;
    }
    @Input() width;
    @Input() showDialog = false;
    @Input() showAffirmButton = true;
    @Input() showDenyButton = true;
    @Input() title: string;
    @Input() bodyText: string;
    @Input() additionalBodyText: string;
    @Input() linkText: string;
    @Input() warningText: string;
    @Input() showConditionalText: boolean;
    @Input() conditionalText: string;
    @Input() affirmText: string;
    @Input() denyText: string;

    @Output() modalEvent = new EventEmitter();
    @Output() closeEvent = new EventEmitter();
    @Output() affirmEvent = new EventEmitter();
    @Output() denyEvent = new EventEmitter();
    @Output() linkEvent = new EventEmitter();

    @ViewChild('modal', { static: true }) modal: DialogModule;
    @ViewChild('affirmButton', { static: true }) affirmButton: ButtonsModule;
    @ViewChild('denyButton', { static: true }) denyButton: ButtonsModule;

    private _modalType: Modal;

    public defaultTitle: string;
    public defaultBodyText: string;
    public defaultAffirmText: string;
    public defaultDenyText: string;

    constructor(
    ) {}

    // SETUP FUNCTIONS //

    ngOnInit() {
        this.setModalType();
    }

    public setModalType(): void {
        switch (this.modalType) {
            case ('continue'):
                this.setContinueModalDefaults();
                break;
            case ('confirm'):
                this.setConfirmModalDefaults();
                break;
            case ('cancel'):
                this.setCancelModalDefaults();
                break;
            case ('save'):
                this.setSaveModalDefaults();
                break;
            case ('delete'):
                this.setDeleteModalDefaults();
                break;
            default:
                this.setContinueModalDefaults();
                break;
        }
    }

    private setContinueModalDefaults(): void {
        this.defaultTitle = CommonConstants.continue;
        this.defaultAffirmText = CommonConstants.continue;
        this.defaultDenyText = CommonConstants.cancel;
        this.defaultBodyText = CommonConstants.continueMessage;
    }

    private setConfirmModalDefaults(): void {
        this.defaultTitle = CommonConstants.confirm;
        this.defaultAffirmText = CommonConstants.yes;
        this.defaultDenyText = CommonConstants.no;
        this.defaultBodyText = CommonConstants.confirmMessage;
    }

    private setCancelModalDefaults(): void {
        this.defaultTitle = CommonConstants.cancel;
        this.defaultAffirmText = CommonConstants.yes;
        this.defaultDenyText = CommonConstants.no;
        this.defaultBodyText = CommonConstants.cancelMessage;
    }

    private  setSaveModalDefaults(): void {
        this.defaultTitle = CommonConstants.save;
        this.defaultAffirmText = CommonConstants.save;
        this.defaultDenyText = CommonConstants.cancel;
        this.defaultBodyText = CommonConstants.saveMessage;
    }

    private setDeleteModalDefaults(): void {
        this.defaultTitle = CommonConstants.delete;
        this.defaultAffirmText = CommonConstants.ok;
        this.defaultDenyText = CommonConstants.cancel;
        this.defaultBodyText = CommonConstants.deleteMessage;
    }

    // EVENT EMITTERS //

    private emitModal(button: string): void {
        this.modalEvent.emit(button);
    }
    private emitClose(): void {
        this.closeEvent.emit(true);
    }
    private emitAffirm(): void {
        this.affirmEvent.emit(true);
    }
    private emitDeny(): void {
        this.denyEvent.emit(true);
    }
    private emitLink(): void {
        this.linkEvent.emit(true);
    }

    // BUTTON FUNCTIONS //

    public closeDialog(): void {
        this.emitModal(CommonConstants.close);
        this.emitClose();
    }

    public affirmDialog(): void {
        if (this.modalType) { this.emitModal(this.affirmText ?? this.defaultAffirmText); }
        this.emitAffirm();
    }

    public denyDialog(): void {
        if (this.modalType) { this.emitModal(this.denyText ?? this.defaultDenyText); }
        this.emitDeny();
    }

    public linkFunction(): void {
        this.emitLink();
    }

}
