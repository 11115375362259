import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AdminAuthGuard, AttendantAuthGuard } from './services/auth-guards.service';
import { HomePage } from './pages/home/home.page';
import { AdminAttendantsPage } from './pages/admin/admin-attendants/admin-attendants.page';
import { AdminAccountsPage } from './pages/admin/admin-accounts/admin-accounts.page';
import { AttendantSearchPage } from './pages/search/attendant-search.page';
import { AttendantProfilePage } from './pages/attendant/attendant-profile/attendant-profile.page';
import { AttendantProfileEditPage } from './pages/attendant/attendant-profile-edit/attendant-profile-edit.page';
import { AttendantAffirmPage } from './pages/attendant/attendant-affirm/attendant-affirm.page';
import { LoginPage } from './pages/login/login.page';
import { RegisterPage } from './pages/register/register.page';
import { RequirementsPage } from './pages/requirements/requirements.page';
import { AttendantReaffirmPage } from './pages/attendant/attendant-reaffirm/attendant-reaffirm.page';


export const routes: Routes = [
  {
    path: 'Home',
    component: HomePage,
    pathMatch: 'full',
  },
  {
    path: 'Admin/Attendants',
    component: AdminAttendantsPage,
    canActivate: [AdminAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'Admin/Accounts',
    component: AdminAccountsPage,
    canActivate: [AdminAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'Search',
    component: AttendantSearchPage,
    pathMatch: 'full'
  },
  {
    path: 'Attendant',
    component: AttendantProfilePage,
    pathMatch: 'full'
  },
  {
    path: 'Attendant/Edit',
    component: AttendantProfileEditPage,
    canActivate: [AttendantAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'Attendant/Affirm',
    component: AttendantAffirmPage,
    pathMatch: 'full'
  },
  {
    path: 'Attendant/Reaffirm/:id',
    component: AttendantReaffirmPage,
    pathMatch: 'full'
  },
  {
    path: 'Login',
    component: LoginPage,
    pathMatch: 'full'
  },
  {
    path: 'Register',
    component: RegisterPage,
    pathMatch: 'full'
  },
  {
    path: 'Requirements',
    component: RequirementsPage,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'Home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
