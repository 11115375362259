import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { TextAreaModule, TextAreaResize } from '@progress/kendo-angular-inputs';
import { ItemDisabledFn } from '@progress/kendo-angular-dropdowns';
import { of, Observable } from 'rxjs';
import { CheckableSettings } from '@progress/kendo-angular-treeview';

import { DatePickerCommonService } from '../services/common-date-picker.service';
import { Size, InputType, Title} from '../constants/common-type-constants';
import { CommonConstants } from '../constants/common-constants';

@Component({
    // tslint:disableInput-next-line:component-selector
    selector: 'CDMS-common-input-control',
    templateUrl: './common-input-control.component.html',
    styleUrls: ['../assets/scss/_common-components.scss'],
})

export class CommonInputControlComponent implements OnInit {
    @Input() show  = true;
    @Input() title: string;
    @Input() placeholder: string;
    @Input() format: string;
    @Input() control: FormControl;
    @Input() validators: ValidatorFn[] | undefined;
    @Input() errorMessage = CommonConstants.errorMessages;
    @Input() options: any[];
    @Input() disableInput = false;
    // Masked Text
    @Input() mask: string;
    // Combobox Type
    @Input() setDisabledOptions: ItemDisabledFn;
    @Input() textField: string;
    @Input() valueField: string;
    // Lookup Type
    @Input() manualDisplayValue: any;
    // Date Type
    @Input() disabledDates: any;
    @Input() readOnlyInput = false;
    @Input() disabledDatesValidation = false;
    // Text Area Type
    @Input() maxLength = 250;
    @Input() showCounter = false;
    // Info Slideout
    @Input() infoSlideout  = false;
    @Input() infoSlideoutTitle: string;
    @Input() infoSlideoutText: string;

    @Input()
    public set size(data: Size) {
        this._size = data;
    }
    public get size() {
        return this._size;
    }

    @Input()
    public set input(data: InputType) {
        this._input = data;
    }
    public get input() {
        return this._input;
    }

    @Input()
    public set titleType(data: Title) {
        this._titleType = data;
    }
    public get titleType() {
        return this._titleType;
    }

    @Input()
    public set value(data: any) {
        this._value = data;
        if (this.input === 'checkboxlist') { this.valueToCheckboxlist(); }
    }
    public get value() {
        return this._value;
    }

    @Output() valueChange = new EventEmitter();
    @Output() clickEvent = new EventEmitter();
    @Output() blurEvent = new EventEmitter();

    @ViewChild('textareaInput', { static: true }) textarea: TextAreaModule;

    private _size: Size = 'medium';
    private _input: InputType = 'text';
    private _titleType: Title = 'top';
    private _value;

    public charCount: Number = 0;
    public counter: String = '';
    public resizable: TextAreaResize = 'none';
    public commonConstants = CommonConstants;

    public children = (dataItem: any): Observable<any[]> => of(dataItem.items);
    public hasChildren = (dataItem: any): boolean => !!dataItem.items;
    public checkedKeys: any[] = [];
    public enableCheck = true;
    public checkChildren = true;
    public checkParents = true;
    public checkOnClick = false;
    public checkMode: any = 'multiple';
    public selectionMode: any = 'single';
    public get checkableSettings(): CheckableSettings {
      return {
        checkChildren: this.checkChildren,
        checkParents: this.checkParents,
        enabled: this.enableCheck,
        mode: this.checkMode,
        checkOnClick: this.checkOnClick,
      };
    }

    constructor(
       public datepickerCommonService: DatePickerCommonService,
    ) {}

    ngOnInit() {
        this.counter = this.charCount.toString() + ' / ' + this.maxLength.toString();
        if (this.input === 'checkboxlist') { this.valueToCheckboxlist(); }
        if (this.input === 'textarea') { this.updateCount(); }
    }

    public valueToCheckboxlist(): void {
        this.checkedKeys = [];
        if (this.value && this.value.length > 0) {
            if (this.valueField) {
                this.value?.forEach(value => {
                    const index = this.options.findIndex(x => x[this.valueField] === value[this.valueField]);
                    if (index !== -1) { this.checkedKeys.push(index.toString()); }
                });
            } else {
                this.value?.forEach(value => {
                    const index = this.options.findIndex(x => x === value);
                    if (index !== -1) { this.checkedKeys.push(index.toString()); }
                });
            }
        }
    }

    public checkboxlistToValue(event): void {
        const index = this.value?.findIndex(x => x.id === event.item.dataItem.id);
        if (index === -1) {
            this.value.push(event.item.dataItem);
        } else {
            if (this.value.length === 1) {
                this.value = [];
            } else {
                this.value.splice(index, 1);
            }
        }
        this.onValueChange(this.value);
    }

    public getFalse(): boolean {
        return false;
    }

    public checkRequired(): boolean {
        if (this.control) {
            if (this.control.validator) {
                const validator = this.control.validator( {} as AbstractControl);
                if (validator && validator.required) {
                    return true;
                }
            }
        }
        return false;
    }

    public updateCount(value = undefined): void {
        if (this.showCounter) {
            this.charCount = value?.length ?? this.value?.length;
            if (this.charCount ){
                this.counter = this.charCount.toString() + ' / ' + this.maxLength.toString();
            }
        }
    }

    public onValueChange(value): void {
        if (this.control) {
            this.control.setValue(value);
        }
        this.updateCount(value);
        this.valueChange.emit(value);
    }

    public onClick(value): void {
        this.clickEvent.emit(value);
    }

    public multiSelectChangeEvent(value: any) {
        if (value) {
            this.control.setValue(value);
            this.valueChange.emit(value);
        }
    }

    public onBlur(): void {
        this.blurEvent.emit();
    }

    public datePickerOnFocus(event): void {
        if (!this.datepickerCommonService.isValidDate(this.value) && this.control) {
            this.control.setValue(undefined);
        }
    }

}
