import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { configName } from 'src/environments/environment';
import { AppConfig } from '../models/config/app-config.interface';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configuration: AppConfig;
  public env = configName.name;

  constructor(
    private httpClient: HttpClient
  ) { }

  public setConfig(): Promise<AppConfig> {
    const configs$ = this.httpClient.get<AppConfig>('../assets/configs/' + this.env + '.config.json');
    return lastValueFrom(configs$).then(config => this.configuration = config);
  }

  public readConfig(): AppConfig {
    return this.configuration;
  }
}
