import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CompositeFilterDescriptor, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, SelectableSettings, GridComponent, DataBindingDirective } from '@progress/kendo-angular-grid';

import { IKendoGridColumnSetting } from './IKendoGridColumnSetting';
import { KendoConstants } from '../constants/common-kendo-constants';
import { CommonConstants } from '../constants/common-constants';
import { CommonFunctions } from '../utilities/common-functions';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'CDMS-common-grid-control',
    templateUrl: './common-grid-control.component.html',
    styleUrls: ['./common-grid-control.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class CommonGridControlComponent implements OnInit, AfterViewInit {

    @Input()
    get kendoGridBindingData(): any[] { return this._kendoGridBindingData; }
    set kendoGridBindingData(value: any[]) {
        if (this.editedRowIndex !== undefined) {
            this.closeEditor();
        }
        this._kendoGridBindingData = (value);
    }

    @Input()
    get skip(): number {
        return this._skip;
    }
    set skip(value: number) {
        this._skip = value;
        this.directive.skip = value;
    }

    @Input()
    get gridData(): GridDataResult { return this._gridData; }
    set gridData(value: GridDataResult) {
        if (this.editedRowIndex !== undefined) {
            this.closeEditor();
        }
        this._gridData = (value);
    }

    @Input()
    rowCallbackClass: (value: any) => any = (_: any) => { };

    @Input()
    columnCallbackExpression: (value: any, field: any) => any = (_: any) => { };

    @Input() id: string;
    @Input() columns: Array<IKendoGridColumnSetting> = [];
    @Input() pageable: boolean | number[] = true;
    @Input() selectable = true;
    @Input() sortable = true;
    @Input() scrollable: any = 'none';
    @Input() styleClassesArray: Array<string> = ['common-grid'];
    @Input() sort: SortDescriptor[] | any;
    @Input() filter: CompositeFilterDescriptor | any;
    @Input() loading: boolean;
    @Input() pageSize: number = KendoConstants.kendoMasterGridProperties.pageSize;
    @Input() class: Array<string>;
    @Input() showCheckBoxCol = false;
    @Input() showCheckBoxColSelectAll = true;
    @Input() selectableSettings: SelectableSettings;
    @Input() resizable = true;
    @Input() reorderable = true;
    @Input() selectedKeys: any;
    @Input() selectedby: any;
    @Input() inlineCreate = false;
    @Input() customCreate = false;
    @Input() inlineEdit = false;
    @Input() inlineCustom = false;
    @Input() customEdit = false;
    @Input() toolbarDelete = false;
    @Input() toolbarCopy = false;
    @Input() inlineDelete = false;
    @Input() toolbarRefresh = false;
    @Input() toolbarExcel = false;
    @Input() errorMessage = CommonConstants.errorMessages;
    @Input() useCommandColumn = false;
    @Input() title: string;
    @Input() isSaveOnStage = true;
    @Input() crossColumnValidators: ValidatorFn[] = [];
    @Input() idParamName = 'id';
    @Input() hasRowClickEvent = false;
    @Input() icon = '';
    @Input() toolbarCustomButtonText = '';
    @Input() toolbarCustomButton = false;
    @Input() saveButtonLabel: String = 'Save';
    @Input() createButtonLabel: String = 'Create';
    @Input() titleTooltip: String = 'Save using ctrl + Enter';
    @Input() headerStyle: 'right' | 'left' = 'right';
    @Input() excelFileName: string;
    @Input() updateEditOn: 'change' | 'blur' | 'submit' = 'blur';
    @Input() modalCreate = false;
    @Input() modalEdit = false;
    @Input() modalOnLoad: Function;
    @Input() modalHeaders: {
        label: string;
        value: string;
    }[];
    @Input() customBtnCmdColTitle = 'Custom Button';
    @Input() customBtnCmdColIcon = 'file-txt';
    @Input() statuses: any;

    @Output() filterChange = new EventEmitter();
    @Output() sortChange = new EventEmitter();
    @Output() pageChange = new EventEmitter();
    @Output() selectionChange = new EventEmitter();
    @Output() multiselectionChangeData = new EventEmitter();
    @Output() redirectAction = new EventEmitter();
    @Output() callContainerMethodAction = new EventEmitter();
    @Output() commonGridIsRendered = new EventEmitter();
    @Output() hyperlink = new EventEmitter();
    @Output() updateEvent = new EventEmitter();
    @Output() createEvent = new EventEmitter();
    @Output() copyEvent = new EventEmitter();
    @Output() deleteEvent = new EventEmitter();
    @Output() customEvent = new EventEmitter();
    @Output() refreshEvent = new EventEmitter();
    @Output() rowClickEvent = new EventEmitter();
    @Output() selectionEmitEvent = new EventEmitter();
    @Output() validationEmitEvent = new EventEmitter();
    @Output() initialSearch = new EventEmitter();
    @Output() modalClose = new EventEmitter();

    @ViewChild(DataBindingDirective, { static: true }) private directive: DataBindingDirective;
    @ViewChild(GridComponent, { static: true }) private grid: GridComponent;

    private saveObject = {};
    private editedRowIndex: number;
    private selection: any[] = [];
    private originalRecord: any;
    private _gridData: GridDataResult;
    private _kendoGridBindingData: any[] = [];
    private _skip: number = KendoConstants.kendoMasterGridProperties.skip;

    public isNew = false;
    public view: any[];
    public formGroup: FormGroup;
    public mySelection: number[] = [];
    public commonConstants: any;
    public selectedKeysInput = true;
    public showModal = false;
    public modalEditEvent: any;
    public savedSelections: {
        record: any;
        count: number;
    }[] = [];

    public get showPager(): boolean {
        return this.kendoGridBindingData && this.kendoGridBindingData.length > 0;
      }

    public get formGroupControls() { return this.formGroup.controls; }

    constructor(
        public commonFunction: CommonFunctions,
    ) { }

    ngAfterViewInit(): void {
        this.commonGridIsRendered.emit();
        if (!this.excelFileName) {
            const today = new Date();
            const date = today.toJSON().slice(0, 10);
            const dateStr = date.slice(8, 10) + '_' + date.slice(5, 7) + '_' + date.slice(0, 4);
            this.excelFileName = this.title ?? 'GridData';
            this.excelFileName += '_' + dateStr + '.xlsx';
        }
    }

    ngOnInit() {
        this.commonConstants = CommonConstants;
        if (!this.selectedKeys) {
            this.selectedKeysInput = false;
            this.selectableSettings = { checkboxOnly: true, enabled: true };
        }
    }

    public getCommandColWidth() {
        let width = 0;
        if (this.inlineEdit) { width += 120; }
        if (this.modalEdit) { width += 60; }
        if (this.inlineDelete) { width += 60; }
        if (this.inlineCustom) { width += 20; }
        return width;
    }

    private createFormGroup(dataItem) {
        const controls: { [k: string]: FormControl } = {};

        if (this.columns.findIndex(x => x.field === this.idParamName) <= 0) {
            controls[this.idParamName] = new FormControl(dataItem[this.idParamName]);
        }

        this.columns.forEach((column) => {
            let value = dataItem[column.field];
            value = this.getEditValue(value, column);

            controls[column.field] = new FormControl(
                {
                    value,
                    disabled: column.controlDisabled
                },
                Validators.compose(column.validators));
        });

        this.columns.forEach(col => {
            if (col.resetValidation) {
                col.resetValidation(controls, controls[col.field].value);
            }
        });

        const formGroup = new FormGroup(
            controls,
            {
                validators: this.crossColumnValidators,
                updateOn: this.updateEditOn,
            }
        );

        if (this.validationEmitEvent) {
            formGroup.statusChanges.subscribe(newStatus => this.validationEmitEvent.emit(newStatus));
        }

        formGroup.markAsUntouched();
        return formGroup;
    }

    private closeEditor(): void {
        this.grid.closeRow(this.editedRowIndex);
        this.editedRowIndex = undefined;
    }

    private saveCurrent(rowIndex: number): void {
        if (this.formGroup) {
            this.columns.forEach(col => {
                if (col.resetValidation) {
                    col.resetValidation(this.formGroup.controls, this.formGroup.controls[col.field].value);
                }
            });

            this.formGroup.updateValueAndValidity();

            if (!this.checkValid() || !this.checkIfChanged()) {
                return;
            }

            const tableRowIndex = rowIndex > this.pageSize ? rowIndex % this.pageSize : rowIndex;
            this.saveObject = {
                ...this.saveObject,
                ...this.grid.data['data'][tableRowIndex]
            };

            for (const control in this.formGroup.controls) {
                if (this.formGroup.controls[control] !== null && this.formGroup.controls[control] !== undefined) {
                    this.saveObject[control] = this.formGroup.controls[control].value;
                }
            }

            if (this.isSaveOnStage) {
                this.saveHandler();
            }
        }
    }

    private saveHandler() {
        const defaultColumns = this.columns.filter(x => x.defaultSaveValue !== null && x.defaultSaveValue !== undefined);
        defaultColumns.forEach(x => {
            if (this.saveObject[x.field] === null
                || (x.defaultEditValue !== undefined && x.defaultEditValue === this.saveObject[x.field])) {
                this.saveObject[x.field] = x.defaultSaveValue;
            }
        });
        this.isNew ? this.createEmit() : this.updateEmit();
        this.formGroup = undefined;
        this.closeEditor();
    }

    private createEmit(): void {
        this.createEvent.emit(this.saveObject);
        this.isNew = false;
    }

    private updateEmit(): void {
        this.saveObject['rowIndex'] = this.editedRowIndex;
        this.updateEvent.emit(this.saveObject);
    }

    private deleteEmit(): void {
        this.deleteEvent.emit(this.selection);
        this.selection = [];
        this.mySelection = [];
    }

    private copyEmit(): void {
        this.copyEvent.emit(this.selection);
        this.selection = [];
        this.mySelection = [];
    }

    private refreshEmit(): void {
        this.refreshEvent.emit();
        this.selection = [];
        this.mySelection = [];
    }

    private selectionEmit(): void {
        this.closeEditor();
        this.selectionEmitEvent.emit(this.selection);
        this.selection = [];
        this.mySelection = [];
    }

    public customEmit(dataItem): void {
        this.customEvent.emit(dataItem);
    }

    public redirect(eventData, column) {
        const data = { ...eventData, column };
        this.redirectAction.emit(data);
    }

    public callContainerMethod(eventData: any) {
        this.callContainerMethodAction.emit(eventData);
    }

    public filterChangeEvent(eventData) {
        if (this.editedRowIndex !== undefined) {
            this.closeEditor();
        }
        this.filterChange.emit(eventData);
    }
    public sortChangeEvent(eventData) {
        if (this.editedRowIndex !== undefined) {
            this.closeEditor();
        }
        this.sortChange.emit(eventData);
    }
    public pageChangeEvent(eventData) {
        if (this.editedRowIndex !== undefined) {
            this.closeEditor();
        }
        this.pageChange.emit(eventData);
    }

    public multiSelectChangeEvent(value: any, formcontrolName: string) {
        if (value) {
            this.formGroupControls[formcontrolName].setValue(value);
        }
    }

    public gotoEdit(eventData, func: Function) {
        if (func) { func(); }
        this.hyperlink.emit(eventData);
    }

    public checkValid() {
        for (const control in this.formGroup?.controls) {
            if (this.formGroupControls[control]?.invalid) {
                return false;
            }
        }
        return true;
    }

    public checkIfChanged() {
        for (const control in this.formGroup?.controls) {
            if (this.formGroupControls[control]?.value !== this.originalRecord[control] && this.originalRecord[control] !== undefined) {
                return true;
            }
        }
        return false;
    }

    private setEditedRow(isEdited, dataItem, rowIndex, columnIndex, update: boolean) {
        // Sets a new row to be editable
        this.originalRecord = dataItem;
        this.formGroup = this.createFormGroup(dataItem);
        this.editedRowIndex = rowIndex;
        if (this.hasRowClickEvent) {
            this.rowClickEvent.emit(this.formGroup.value);
        }
        (this.modalEdit)
            ? this.triggerModalEdit({ isEdited, dataItem, rowIndex, columnIndex })
            : (this.customEdit && update)
                ? this.updateEvent.emit(dataItem)
                : this.grid.editRow(rowIndex, this.formGroup, columnIndex ? { columnIndex } : null);
    }

    public ctrlEnterEvent({ rowIndex }) {
        if (this.checkValid()) {
            this.saveCurrent(rowIndex);
        }
    }

    public rowClickHandler({ isEdited, dataItem, rowIndex, columnIndex }) {
        if (this.formGroup && !this.modalEdit) {
            if (!this.checkValid()) {
                return;
            } else if (!this.checkIfChanged() && rowIndex !== this.editedRowIndex) {
                this.closeEditor();
                this.setEditedRow(isEdited, dataItem, rowIndex, columnIndex, false);
                return;
            }
        }

        if (!this.modalEdit) {
            this.saveCurrent(rowIndex);
        }

        this.setEditedRow(isEdited, dataItem, rowIndex, columnIndex, true);
    }

    public addClickHandler() {
        if (this.customCreate) {
            this.createEvent.emit(this.formGroup);
        } else {
            this.closeEditor();
            this.formGroup = this.createFormGroup('add');
            this.columns.forEach(col => {
                if (col.defaultDisplayValue !== null && col.defaultDisplayValue !== undefined) {
                    this.formGroup.controls[col.field].setValue(col.defaultDisplayValue);
                }
            });
            this.isNew = true;
            this.originalRecord = this.formGroup.value;
            (this.modalCreate)
                ? this.showModal = true
                : this.grid.addRow(this.formGroup);
        }
    }

    public emitSelectedItems(eventType: string) {
        let gridData: any = [];
        if (this.kendoGridBindingData !== undefined && this.kendoGridBindingData.length > 0) {
            gridData = this.kendoGridBindingData;
        } else {
          gridData = this.grid && this.grid.data ? this.grid.data['data'] : [];
        }
        for (const rowIndex of this.mySelection) {
            this.selection.push(gridData[rowIndex] ?? gridData[rowIndex - this._skip ?? 0]);
        }
        switch (eventType) {
            case 'Custom':
                this.selectionEmit();
                break;
            case 'Delete':
                this.deleteEmit();
                break;
            case 'Copy':
                this.copyEmit();
                break;
            default:
                this.refreshEmit();
                break;
        }
    }

    public selectionChangeEvent(eventData) {
        this.selectionChange.emit(eventData.selectedRows);
        this.multiselectionChangeData.emit(eventData.selectedRows);
    }

    public clearSelected() {
        this.selection = [];
        this.mySelection = [];
        this.savedSelections = [];
    }

    public deleteRow({ rowIndex }) {
        this.selection.push(this.grid.data['data'][rowIndex]);
        this.deleteEmit();
    }

    public deleteRowCommandCol(eventData) {
        const rowIndex = this.grid.data['data'].findIndex(x => x === eventData);
        this.selection.push(this.grid.data['data'][rowIndex]);
        this.deleteEmit();
    }

    public saveClickHandler({ rowIndex }) {
        this.saveCurrent(rowIndex);
    }

    public escHandler() {
        this.formGroup = undefined;
        this.isNew = false;
        this.closeEditor();
    }

    public getItemFromOptions(id: string, options: any[], idName) {
        for (const option of options) {
            if (this.getProp(option, idName) === id) {
                return option;
            }
        }
        return id;
    }

    public getDisplayValue(value, column: IKendoGridColumnSetting) {
        if (value === null || value === undefined) {
            if (column.changeValueToDefaultConditional !== undefined && column.changeValueToDefaultConditional(value)
                && column.defaultDisplayValue !== null && column.defaultDisplayValue !== undefined) {
                return column.defaultDisplayValue;
            }
            return null;
        }
        return value;
    }

    private getEditValue(value, column: IKendoGridColumnSetting) {
        if (value === null || value === undefined
            || (column.changeValueToDefaultConditional !== undefined && column.changeValueToDefaultConditional(value))) {
            if (column.defaultEditValue != null) {
                return column.defaultEditValue;
            }
            if (column.valueField !== null && column.valueField !== undefined) {
                return column.valueField;
            }
            return null;
        }
        return value;
    }

    private getProp(obj, prop) {
        if (typeof obj !== 'object') { throw new Error('getProp: obj is not an object'); }
        if (typeof prop !== 'string') { throw new Error('getProp: prop is not a string'); }

        // Replace [] notation with dot notation
        prop = prop.replace(/\[["'`](.*)["'`]\]/g, '.$1');

        return prop.split('.').reduce(function(prev, curr) {
            return prev ? prev[curr] : undefined;
        }, obj || self);
    }

    // Modal Event Listeners
    public modalSave(modalForm: FormGroup) {
        this.grid.addRow(modalForm);
        this.modalEditEvent = undefined;
        this.showModal = false;
        this.saveCurrent(0);
        this.modalClose.emit('save');
    }

    public modalUpdate(modalForm: FormGroup) {
        this.formGroup = modalForm;
        this.modalEditEvent = undefined;
        this.showModal = false;
        this.saveCurrent(this.editedRowIndex);
        this.modalClose.emit('update');
    }

    public modalCancel() {
        this.modalEditEvent = undefined;
        this.showModal = false;
        this.modalClose.emit('cancel');
    }

    public triggerModalEdit(editEvent) {
        this.isNew = false;
        this.modalEditEvent = editEvent;
        this.showModal = true;
    }

}
