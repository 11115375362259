import { Component, OnInit } from '@angular/core';
import { LanguageChangeService } from 'src/app/services/language-change.service';
import { UserService } from './controllers/user/user.controller';
import { StorageConstants } from './constants/storage-constants';
import { AppConstants } from './constants/app-constants';
import { Notification } from './common-components/services/common-notification.service';
import { NotificationSystemService, INotification } from './common-components/controllers/common-notification-system.controller';
import { LanguagesService } from './controllers/reference-data/languages.controller';
import { WorkPreferencesService } from './controllers/reference-data/work-preferences.controller';
import { ServicesService } from './controllers/reference-data/services.controller';
import { CertificatesService } from './controllers/reference-data/certificates.controller';
import { AdminApprovalService } from './controllers/admin/admin-approval.controller';
import { AttendantProfileService } from './controllers/attendant/attendant-profile.controller';
import { AnalyticsService } from 'src/app/services/analytics.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss', '../assets/scss/_kendo-overrides.scss',],
})
export class AppComponent implements OnInit {

  public load = {
    certs: false,
    servs: false,
    workPref: false,
    langPref: false,
    user: false,
    adminApp: false,
    attProf: false
  };

  constructor(
    private languageChangeService: LanguageChangeService,
    private userService: UserService,
    private certificateService: CertificatesService,
    private servicesService: ServicesService,
    private workPreferencesService: WorkPreferencesService,
    private languagesService: LanguagesService,
    private attendantProfileService: AttendantProfileService,
    private adminApprovalService: AdminApprovalService,
    private toastr: Notification,
    private notificationSystemService: NotificationSystemService,
    private analyticsService: AnalyticsService,
  ) {

    analyticsService.loadGoogleAnalytics();

    this.userService.getLoad().subscribe((data) => {
      this.load.user = data;
    });
    this.certificateService.getLoad().subscribe((data) => {
      this.load.certs = data;
    });
    this.servicesService.getLoad().subscribe((data) => {
      this.load.servs = data;
    });
    this.workPreferencesService.getLoad().subscribe((data) => {
      this.load.workPref = data;
    });
    this.languagesService.getLoad().subscribe((data) => {
      this.load.langPref = data;
    });
    this.attendantProfileService.getLoad().subscribe((data) => {
      this.load.adminApp = data;
    });
    this.adminApprovalService.getLoad().subscribe((data) => {
      this.load.attProf = data;
    });

    this.notificationSystemService.getNotification().subscribe((data: INotification) => {
      if (data) {
        switch (data.type) {
          case 'success':
            this.toastr.success(data.content, data.close);
            break;
          case 'error':
            this.toastr.error(data.content, data.close);
            break;
          case 'info':
            this.toastr.info(data.content, data.close);
            break;
          case 'warning':
            this.toastr.warning(data.content, data.close);
            break;
        }
      }
    });
  }

  ngOnInit() {
    const selectedlanguage = sessionStorage.getItem(StorageConstants.selectedlanguage);
    if (selectedlanguage) {
      this.languageChangeService.use(selectedlanguage);
    } else {
      this.languageChangeService.use(AppConstants.defaultLanguage);
    }
  }
}
