import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, } from 'rxjs';
import { Align, AnimationDirection, AnimationType, PopupAnimation, } from '@progress/kendo-angular-popup';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult,InteractionType, PopupRequest, RedirectRequest} from '@azure/msal-browser';

import { Roles, Pages } from '../../constants/app-types';
import { IPageDetails } from '../../constants/app-interfaces';
import { PageDetailArrayCO, PageDetailArrayCOES,
    PageDetailArray, PageDetailArrayES, locales } from '../../constants/app-constants';
import { StorageConstants } from '../../constants/storage-constants';
import { AppConstants } from '../../constants/app-constants';
import { AppbarService } from './appbar-service';
import { UserService } from '../../controllers/user/user.controller';
import { LanguageChangeService } from '../../services/language-change.service';
import { AttendantProfileService } from '../../controllers/attendant/attendant-profile.controller';
import MsalServiceWrapper from '../../services/msal.wrapper.service';
import { ConfigService } from '../../services/config.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'CDMS-appbar-control',
    templateUrl: './appbar-control.component.html',
    styleUrls: ['./appbar-control.component.scss'],
})

export class AppbarControlComponent implements OnInit {
    @Input()
    public set animation(data: PopupAnimation) {
        this._animation = data;
    }
    public get animation() {
        return this._animation;
    }

    @Input() show = true;
    @Input() enabled = true;

    @HostListener('document:click', ['$event'])
    public documentClick(event: KeyboardEvent): void {
      if (!this.containsMenu(event.target)) {
        this.toggleMenu(false);
      }
      if (!this.containsHelp(event.target)) {
        this.toggleHelp(false);
      }
    }

    @ViewChild('menuButton') public anchorMenu: ElementRef;
    @ViewChild('menuPopup', { read: ElementRef }) public popupMenu: ElementRef;
    @ViewChild('helpButton') public anchorHelp: ElementRef;
    @ViewChild('helpPopup', { read: ElementRef }) public popupHelp: ElementRef;

    public colorado = this.configService.readConfig().colorado;
    public showMenu = false;
    public showHelp = false;
    public page: Pages = 'home';
    public role: Roles = 'User';
    public languageText = locales[1].text;
    public currentLanguage = new Subject<string>();
    public anchorAlignMenu: Align = { horizontal: 'center', vertical: 'bottom' };
    public popupAlignMenu: Align = { horizontal: 'center', vertical: 'top' };
    public animationType: AnimationType = 'slide';
    public animationDir: AnimationDirection = 'down';
    public activePageDetail: IPageDetails;
    private _animation: PopupAnimation = {
        type: this.animationType,
        direction: this.animationDir,
        duration: 500,
    };
    public isIframe = false;
    private _id: string;

    constructor(
        private appbarService: AppbarService,
        private userService: UserService,
        private router: Router,
        private languageChangeService: LanguageChangeService,
        private authService: MsalService,
        private attendantProfileService: AttendantProfileService,
        private msalService: MsalServiceWrapper,
        private configService: ConfigService,
    ) {
        this.isIframe = window !== window.parent && !window.opener;
      }

    ngOnInit() {
        this.appbarService.getPageTrigger().subscribe((data) => {
            if (data !== undefined) {
                this.page = data;
                this.activePageDetail =
                (this.colorado)
                    ? (AppConstants.selectedLanguage === 'es')
                        ? PageDetailArrayCOES.find(x => x.page === data)
                        : PageDetailArrayCO.find(x => x.page === data)
                    : (AppConstants.selectedLanguage === 'es')
                        ? PageDetailArrayES.find(x => x.page === data)
                        : PageDetailArray.find(x => x.page === data);
            };
        });
        this.appbarService.getRoleTrigger().subscribe((data) => {
            if (data !== undefined) {
                this.role = data;
            };
        });
        this.userService.getId().subscribe((data) => {
            if (data !== undefined) {
                this._id = data;
            };
        });
    }

    public get getAnimation(): boolean | PopupAnimation {
        if (this.enabled) {
          return this._animation;
        }
        return false;
    }

    private reset() {
        this.showMenu = false;
        this.showHelp = false;
    }

    public toggleMenu(status?: boolean) {
        if (typeof status !== 'undefined') { this.showMenu = status; }
        else { this.showMenu = !this.showMenu; }
    }

    public toggleHelp(status?: boolean) {
        if (typeof status !== 'undefined') { this.showHelp = status; }
        else { this.showHelp = !this.showHelp; }
    }

    private containsMenu(target: EventTarget): boolean {
        return (
          this.anchorMenu?.nativeElement.contains(target) ||
          (this.popupMenu ? this.popupMenu.nativeElement.contains(target) : false)
        );
    }

    private containsHelp(target: EventTarget): boolean {
        return (
          this.anchorHelp?.nativeElement.contains(target) ||
          (this.popupHelp ? this.popupHelp.nativeElement.contains(target) : false)
        );
    }

    public goHome() {
        this.reset();
        this.router.navigate(['/Home']);
    }

    public goProfile() {
        this.reset();
        this.attendantProfileService.clear();
        this.attendantProfileService.search(this._id);
        this.router.navigate(['/Attendant']);
    }

    public goBack() {
        this.reset();
        window.history.back();
    }

    public goLogin(userFlowRequest?: RedirectRequest | PopupRequest) {
        this.msalService.initiateFlow('SignInSignUp');
    }

    public goAttendants() {
        this.reset();
        this.router.navigate(['/Admin/Attendants']);
    }

    public goAccounts() {
        this.reset();
        this.router.navigate(['/Admin/Accounts']);
    }

    public changeLanguage() {
        this.reset();

        let preferredLang = sessionStorage.getItem(StorageConstants.selectedlanguage);
        if (!preferredLang) {
            preferredLang = AppConstants.defaultLanguage;
        }
        switch (preferredLang) {
            case locales[0].code:
                const languageModelEn = locales[1];
                this.languageChangeService.use(languageModelEn.code);
                sessionStorage.setItem(StorageConstants.selectedlanguage, languageModelEn.code);
                this.languageText = locales[0].text;
                this.currentLanguage.next(locales[1].code);
                AppConstants.isEsPreferredLang = true;
                AppConstants.selectedLanguage = 'es';
                break;
            case locales[1].code:
                const languageModelEs = locales[0];
                this.languageChangeService.use(languageModelEs.code);
                sessionStorage.setItem(StorageConstants.selectedlanguage, languageModelEs.code);
                this.languageText = locales[1].text;
                this.currentLanguage.next(locales[0].code);
                AppConstants.isEsPreferredLang = false;
                AppConstants.selectedLanguage = 'en';
                break;
            default:
            break;
        }

        this.activePageDetail =
        (this.colorado)
            ? (AppConstants.isEsPreferredLang)
                ? PageDetailArrayCOES.find(x => x.page === this.page)
                : PageDetailArrayCO.find(x => x.page === this.page)
            : (AppConstants.isEsPreferredLang)
                ? PageDetailArrayES.find(x => x.page === this.page)
                : PageDetailArray.find(x => x.page === this.page);

        this.appbarService.setPage();
        this.appbarService.refresh();
        return this.languageText;
    }

    public signOut() {
        this.reset();
        this.authService.logout();
        this.userService.updateRole(['User']);
        this.router.navigate(['/Home']);
    }

    public complete() {
        this.appbarService.complete();
        this.appbarService.clear();
    }

    public cancel() {
        this.appbarService.cancel();
        this.appbarService.clear();
    }

    public delete() {
        this.appbarService.delete();
        this.appbarService.clear();
    }

    public spam() {
        this.appbarService.spam();
        this.appbarService.clear();
    }

}
